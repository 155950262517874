<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title p-d-flex p-ai-center" v-if="itemData?.id">
        <div>Edit purchase invoice {{ itemData.number }}</div>
        <i v-if="itemData.changed_after_export" style="font-size: 1.2rem;" class="pi pi-exclamation-triangle warning-color p-ml-1" v-tooltip.top="'Changed after export'"></i>
      </div>
      <div class="p-dialog-title" v-else>New purchase invoice</div>
    </template>
    <MessageBar v-show="invoiceFinancialPeriodIsClosed || selectedIssuedDateFinancialPeriodIsClosed"
                :text="'Warning! Financial period already closed.'"
                :fixedToTop="false"
                :severity="'warn'"/>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label dropdown-wrapper">
          <CustomDropdown id="supplier"
                          :disabled="supplierChangeDisabled"
                          ref="selectedSupplierDropdown"
                          class="dropdown__select dropdown__select--one-button-width"
                          :class="{'p-invalid' : submitted && !selectedSupplier}"
                          :clearSearchData="clearModalData"
                          v-model="selectedSupplier"
                          :options="suppliersData"
                          filterPlaceholder="Find supplier"
                          :filterFields="['company_name', 'name']"
                          :filter="true"
                          :createButton="true"
                          :loading="suppliersDataIsLoading"
                          @change="changeSupplier"
                          @filter="searchSuppliers"
                          @create-new="addNewSupplier"
                          :showClear="!supplierChangeDisabled">
<!--                       optionLabel="company_name"-->
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value.company_name }} <span v-if="slotProps.value.name">({{ slotProps.value.name }})</span></div>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option">
                <div>{{ slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>
              </div>
            </template>
          </CustomDropdown>
          <label for="supplier">{{ $t('Supplier') }}<span class="warning-color">*</span></label>
          <div class="dropdown__buttons">
            <Button :disabled="supplierChangeDisabled" @click="addNewSupplier" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
          </div>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedSupplier">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <InputText id="invoice_number"
                     @input="checkNumberExists"
                     :class="{'p-invalid' : (submitted && !itemData.number) || invoiceNumberExists}"
                     v-model.trim="itemData.number"
                     autocomplete="off"/>
          <label for="invoice_number">Number<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.number">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="invoiceNumberExists">{{ $t('Invoice number already exists') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="purchase_invoice_state"
                    :class="{'p-invalid' : submitted && !selectedState}"
                    v-model="selectedState"
                    :options="PurchaseInvoiceStates"
                          :preventFilter="true"
                    :filter="false"
                    :showClear="false">
<!--            optionLabel="label"-->
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span class="state-badge" :class="slotProps.value.color">{{ slotProps.value.label[$i18n.locale] }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span class="state-badge" :class="slotProps.option.color">{{ slotProps.option.label[$i18n.locale] }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="purchase_invoice_state">State<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedState">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="changeIssuedDate"-->
<!--          @blur="changeIssuedDate"-->
          <Calendar
              id="invoice_issued"
              @date-select="changeIssuedDate"
              @blur="changeIssuedDate"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              v-model="issued"
              :class="{'p-invalid' : (issued && !issuedDateIsValid) || submitted && !issued ||  (issued && payment_due && payment_due < issued)}"
              :dateFormat="settings.dateFormat"
              :showIcon="true"
              :monthNavigator="true"
              :yearNavigator="true"
              :showButtonBar="true"
              :yearRange="(new Date().getFullYear() - 5) + ':' + new Date().getFullYear()"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <label for="invoice_issued">Issued<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !issued">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="issued && !issuedDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">
        <div class="p-float-label">
<!--          @input="checkPaymentDueDateIsValid"-->
<!--          @blur="checkPaymentDueDateIsValid"-->
<!--          @date-select="checkPaymentDueDateIsValid"-->
          <Calendar
              id="payment_due"
              @date-select="changePaymentDueDate"
              @blur="changePaymentDueDate"
              @show="toogleDropdownOverlayVisibility(true)"
              @hide="toogleDropdownOverlayVisibility(false)"
              v-model="payment_due"
              :class="{'p-invalid' : (payment_due && !paymentDueDateIsValid) || submitted && !payment_due || (issued && payment_due && payment_due < issued)}"
              :dateFormat="settings.dateFormat"
              :showIcon="true"
              :monthNavigator="true"
              :yearNavigator="true"
              :showButtonBar="true"
              :yearRange="(new Date().getFullYear() - 5) + ':' + (new Date().getFullYear() + 1)"
              :selectOtherMonths="true"
              autocomplete="off"/>
          <label for="payment_due">Payment due<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !payment_due">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="payment_due && !paymentDueDateIsValid">{{ $t('Invalid date format') }}</small>
      </div>


<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-4 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputText disabled id="invoice_secondary_number" v-model.trim="itemData.secondary_number" autocomplete="off"/>-->
<!--          <label for="invoice_secondary_number">Secondary number</label>-->
<!--        </div>-->
<!--      </div>-->

<!--    <div class="p-formgrid p-grid">-->
<!--      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <div class="p-field p-mb-5">-->
<!--          <div class="p-float-label p-input-icon-right" >-->
<!--            <i class="ti-comment-alt"></i>-->
<!--            <Textarea id="invoice_comment" v-model.trim="itemData.comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--            <label for="invoice_comment">{{ $t('Comment') }}</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 add-position-buttons">
        <Button @click="addNewPosition(1)" v-tooltip.top="'Add warehouse item'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-shopping-cart"></i>
        </Button>
        <Button @click="addNewPosition(2)" v-tooltip.top="'Add free position'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-pencil"></i>
        </Button>
        <Button @click="addNewPosition(3)" v-tooltip.top="'Add consumable'"  class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-spray"></i>
        </Button>
        <Button @click="addRequestPositions" :disabled="disabledAddRequestPositionsButton" v-tooltip.top="'Add request'" class="p-button-outlined add-position-button">
          <i class="ti-plus p-mr-1"></i>
          <i class="ti-truck"></i>
        </Button>
        <Button @click="addReducedPositions" :disabled="disabledAddReducedPositionsButton" v-tooltip.top="'Add reduced position'" class="p-button-outlined add-position-button">
          <i class="ti-minus p-mr-1"></i>
          <i class="ti-truck"></i>
        </Button>
<!--        <Button style="color: #07db00" @click="addWarehouseRequestPositions" v-tooltip.top="'Add warehouse request'" class="p-button-outlined add-position-button p-mr-5">-->
<!--          <i class="ti-minus p-mr-1"></i>-->
<!--          <i class="ti-shopping-cart"></i>-->
<!--        </Button>-->
        <Button @click="addNewPosition(6)" v-tooltip.top="'Add return request'" class="p-button-outlined add-position-button warning-color" :class="{'p-mr-5' : settings.useSmartAccounts}">
          <i class="ti-minus p-mr-1"></i>
          <i class="ti-truck"></i>

        </Button>
        <div class="p-d-flex p-ai-center p-mt-3 p-mb-3" v-if="settings.useSmartAccounts">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="export_to_smart_accounts_switch"
                         :disabled="exportInvoiceToSmartAccountsDisabled"
                         v-model="exportInvoiceToSmartAccounts"/>
          </div>
          <label for="export_to_smart_accounts_switch" class="switch-label pointer" :class="{'no-pointer':exportInvoiceToSmartAccountsDisabled}">Export to SmartAccounts</label>
        </div>
      </div>
<!--    </div>-->
<!--    </div>-->
      <div v-if="!noPositions"  class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5 table-wrapper">
<!--    <div class="table-wrapper">-->
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-thead">
          <tr>
            <th class=""></th>
            <th class="table-title__name--first" v-if="settings.useSmartAccounts && exportInvoiceToSmartAccounts">SA Code</th>
<!--            <th class="table-title__name&#45;&#45;first"></th>-->
<!--            <th class="table-title__name&#45;&#45;second">Code</th>-->
            <th class="table-title__name--second">Code/Item</th>
            <th class="table-title__name--last">Name</th>
            <th class="table-title__qty">Qty</th>
            <th class="table-title__purchase">Buy</th>
            <th class="table-title__sell">Sell</th>
<!--            <th class="table-title__discount">Dis.(%)</th>-->
            <th class="table-title__sum">Sum</th>
            <th class="table-title__tax">Tax</th>
            <th class="table-title__total">Total</th>
            <th v-if="invoiceHavePositionsWithExceedsFixedQty"></th>
            <th v-if="invoiceHaveNotNewPositions"></th>
            <th></th>
            <th class="table-title__delete-btn"></th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr v-for="(position, index) of positions"
                :key="index">
<!--              @mousedown="rowOnMouseDown"-->
              <template v-if="position.status">
                <td :data-index="index"
                    draggable="true"
                    @dragstart="handleDragStart"
                    @dragenter="handleDragEnter"
                    @dragleave="handleDragLeave"
                    @dragover="handleDragOver"
                    @drop="handleDrop"
                    class="grabbable" :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
<!--                  <i class="grabbable" :class="{'ti-plus p-mr-1' : position.type === 4, 'ti-minus p-mr-1' : position.type === 5 || position.type === 7, 'ti-minus warning-color p-mr-1' : position.type === 6}"></i>-->
<!--                  <i class="grabbable" :class="{'ti-shopping-cart': position.type === 1, 'ti-pencil' : position.type === 2, 'ti-spray' : position.type === 3, 'ti-truck' : position.type === 4 || position.type === 5 || position.type === 7, 'ti-truck warning-color' : position.type === 6}"></i>-->
                  <div class="p-d-flex p-ai-center grabbable">
<!--                    <div class="p-mr-2 grabbable" v-if="position.sorting">{{ position.sorting }}</div>-->
                    <div class="grabbable">
                      <i class="grabbable" :class="{'ti-plus p-mr-1' : position.type === 4, 'ti-minus p-mr-1' : position.type === 5 || position.type === 7, 'ti-minus warning-color p-mr-1' : position.type === 6}"></i>
                      <i class="grabbable" :class="{'ti-shopping-cart': position.type === 1, 'ti-pencil' : position.type === 2, 'ti-spray' : position.type === 3, 'ti-truck' : position.type === 4 || position.type === 5 || position.type === 7, 'ti-truck warning-color' : position.type === 6}"></i>
                    </div>
                  </div>
                </td>
                <td v-if="settings.useSmartAccounts && exportInvoiceToSmartAccounts" :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
<!--                  @change="changeWarehouseItem(position)"-->
<!--                  @filter="searchWarehouseItems($event, position)"-->
                  <CustomDropdown :class="{'p-invalid' : submitted && exportInvoiceToSmartAccounts && !position.selectedSmartAccountsItem}"
                                      class="table-body__name-input--accounting-code"
                                      v-model="position.selectedSmartAccountsItem"
                                      :options="smartAccountsItems"
                                      @change="changeSmartAccountsItem(position)"
                                      placeholder="Select item"
                                      :filter="true"
                                      :filterFields="['code', 'description']"
                                      :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span v-if="slotProps.value.code">{{ slotProps.value.code }} - </span>{{ slotProps.value.description }}
                      </div>
                      <span v-else>Select item</span>
                    </template>
                    <template #option="slotProps">
                      <div><span v-if="slotProps.option.code">{{ slotProps.option.code }} - </span>{{ slotProps.option.description }}</div>
                    </template>
                  </CustomDropdown>
                </td>
                <template v-if="position.type === 1">
                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                    <div class="dropdown-wrapper table-body__name-input--first">
                      <CustomDropdown class="dropdown__select"
                                          :class="{'p-invalid' : submitted && !position.selectedWarehouseItem, 'dropdown__select--border-radius' : position.status === 0 || !position.isNew, 'dropdown__select--one-button-width' : position.status !== 0 && position.isNew }"
                                          :disabled="position.status === 0 || !position.isNew"
                                          v-model="position.selectedWarehouseItem"
                                          @change="changeWarehouseItem(position)"
                                          @filter="searchWarehouseItems($event, position)"
                                          placeholder="Select item"
                                          :options="position.warehouseItemsData || warehouseItemsData"
                                          :filter="true"
                                          :filterFields="['code', 'raw_code', 'name']"
                                          :showClear="false">
                        <template #value="slotProps">
                          <div v-if="slotProps.value">
                            <span>{{ slotProps.value.name }}</span>
                          </div>
                          <span v-else>Select item</span>
                        </template>
                        <template #option="slotProps">
                          <div>
                            <div>{{ slotProps.option.name }}<span v-if="slotProps.option.code" style="">, Code: {{ slotProps.option.code }}</span></div>
                            <span class="p-mt-1" style="font-size: 0.9rem">Qty: {{ formatDecimal(slotProps.option.qty) }}, Reserved: {{ formatDecimal(slotProps.option.reserved_qty) }}, Available: {{ formatDecimal(slotProps.option.qty - slotProps.option.reserved_qty) }}</span>
                          </div>
                        </template>
                      </CustomDropdown>
                      <Button v-if="position.status !== 0 && position.isNew" @click="addNewWarehouseItem(position)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                    </div>
                  </td>
<!--                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">-->
<!--                    <InputText :placeholder="$t('Code')" disabled v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input&#45;&#45;code" autocomplete="off"/>-->
<!--                  </td>-->
<!--                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">-->
<!--                    <InputText :disabled="!position.selectedWarehouseItem || position.status === 0"-->
<!--                               v-model.trim="position.name"-->
<!--                               :placeholder="$t('Name')"-->
<!--                               @input="updatePosition(position)"-->
<!--                               :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name" autocomplete="off"/>-->
<!--  &lt;!&ndash;                  <InputText :placeholder="$t('Name')" v-model.trim="position.name" @input="updatePosition(position)" :class="{'p-invalid' : submitted && !position.name}" class="table-body__name-input&#45;&#45;third" autocomplete="off"/>&ndash;&gt;-->
<!--                  </td>-->
                </template>

                <template v-else-if="position.type === 7">
                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
<!--                    <div>{{ position.searchString }}</div>-->
                    <div class="dropdown-wrapper table-body__name-input--first">
                      <CustomDropdown class="dropdown__select"
                                          :class="{'p-invalid' : submitted && !position.selectedWarehouseItem, 'dropdown__select--border-radius' : position.status === 0 || !position.isNew, 'dropdown__select--one-button-width' : position.status !== 0 && position.isNew }"
                                          :disabled="position.status === 0 || !position.isNew"
                                          v-model="position.selectedWarehouseItem"
                                          @change="changeReducedWarehouseItem(position)"
                                          @filter="searchWarehouseItems($event, position)"
                                          :searchData="position.searchData"
                                          placeholder="Select item"
                                          :options="position.warehouseItemsData || warehouseItemsData"
                                          :filter="true"
                                          :filterFields="['code', 'raw_code', 'name']"
                                          :showClear="false">
                        <template #value="slotProps">
                          <div v-if="slotProps.value">
                            <span>{{ slotProps.value.name }}</span>
                          </div>
                          <span v-else>Select item</span>
                        </template>
                        <template #option="slotProps">
                          <div>
                            <div>{{ slotProps.option.name }}<span v-if="slotProps.option.code" style="">, Code: {{ slotProps.option.code }}</span></div>
                            <span class="p-mt-1" style="font-size: 0.9rem">Qty: {{ formatDecimal(slotProps.option.qty) }}, Reserved: {{ formatDecimal(slotProps.option.reserved_qty) }}, Available: {{ formatDecimal(slotProps.option.qty - slotProps.option.reserved_qty) }}</span>
                          </div>
                        </template>
                      </CustomDropdown>
                      <Button v-if="position.status !== 0 && position.isNew" @click="addNewWarehouseItem(position, true)" class="dropdown__create-button p-d-flex p-ai-center p-jc-center"><i class="ti-plus"></i></Button>
                    </div>
                  </td>
<!--                  <td :class="{'new-item' : position.isNew, 'updated-item': position.isUpdated}">-->
<!--                    <InputText :placeholder="$t('Code')" disabled v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input&#45;&#45;code" autocomplete="off"/>-->
<!--                  </td>-->
<!--                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">-->
<!--                    <InputText :disabled="!position.selectedWarehouseItem || position.status === 0"-->
<!--                               v-model.trim="position.name"-->
<!--                               :placeholder="$t('Name')"-->
<!--                               @input="updatePosition(position)"-->
<!--                               :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"-->
<!--                               class="table-body__name-input&#45;&#45;name" autocomplete="off"/>-->
<!--                    &lt;!&ndash;                  <InputText :placeholder="$t('Name')" v-model.trim="position.name" @input="updatePosition(position)" :class="{'p-invalid' : submitted && !position.name}" class="table-body__name-input&#45;&#45;third" autocomplete="off"/>&ndash;&gt;-->
<!--                  </td>-->
                </template>

                <template v-else>
                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                    <InputText :placeholder="$t('Code')" v-model.trim="position.code" @input="updatePosition(position, $event.value, 'code')" class="table-body__name-input--code" autocomplete="off"/>
                  </td>
<!--                  <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">-->
<!--                    <InputText :placeholder="$t('Name')" v-model.trim="position.name" @input="updatePosition(position, false, 'name')" :class="{'p-invalid' : submitted && !position.name}" class="table-body__name-input&#45;&#45;name" autocomplete="off"/>-->
<!--                  </td>-->
                </template>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
<!--                  @input="updatePosition(position)"-->
                  <InputText :disabled="(position.type === 1 && !position.selectedWarehouseItem) || position.status === 0"
                             v-model.trim="position.name"
                             :placeholder="$t('Name')"
                             @input="updatePosition(position, false, 'name')"
                             :class="{'p-invalid' : submitted && position.selectedWarehouseItem && !position.name}"
                             class="table-body__name-input--name" autocomplete="off"/>
                  <!--                  <InputText :placeholder="$t('Name')" v-model.trim="position.name" @input="updatePosition(position)" :class="{'p-invalid' : submitted && !position.name}" class="table-body__name-input&#45;&#45;third" autocomplete="off"/>-->
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
  <!--                TYPE_STOCK-->
                  <InputNumber v-if="position.type === 1"
                               :locale="computedNumberInputLocale"
                               :disabled="!position.isNew"
                               @input="updatePosition(position, $event.value, 'qty', 99999, -99999)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               :min="-99999"
                               :max="99999"
                               :showButtons="position.isNew"
                               :minFractionDigits="!position.is_decimal ? 0 : 2"
                               :maxFractionDigits="!position.is_decimal ? 0 : 2"
                               autocomplete="off"/>
  <!--                REQUEST/RETURN/RETURN_REQUEST-->
                  <InputNumber v-else-if="position.type === 4"
                               :locale="computedNumberInputLocale"
                               :disabled="!position.isNew"
                               @input="updatePosition(position, $event.value, 'qty', position.unfixed_qty, 0)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : (submitted && !position.qty) || position.exceedsFixedQty}"
                               class="table-body__qty-input"
                               :min="0"
                               :max="position.unfixed_qty"
                               :showButtons="position.isNew"
                               autocomplete="off"/>
                  <InputNumber v-else-if="position.type === 5"
                               :locale="computedNumberInputLocale"
                               :disabled="!position.isNew"
                               @input="updatePosition(position, $event.value, 'qty', 0, position.unfixed_qty * -1)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               :min="position.unfixed_qty * -1"
                               :max="0"
                               :showButtons="position.isNew"
                               autocomplete="off"/>
                  <InputNumber v-else-if="position.type === 6"
                               :locale="computedNumberInputLocale"
                               :disabled="!position.isNew"
                               @input="updatePosition(position, $event.value, 'qty', 99999, 0)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               :min="0"
                               :max="99999"
                               :showButtons="position.isNew"
                               autocomplete="off"/>
<!--                  WAREHOUSE_REQUEST-->
                  <InputNumber v-else-if="position.type === 7"
                               :locale="computedNumberInputLocale"
                               :disabled="!position.isNew"
                               @input="updatePosition(position, $event.value, 'qty', position.unfixed_qty, 0)"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               :min="0"
                               :max="position.unfixed_qty"
                               :showButtons="position.isNew"
                               autocomplete="off"/>
  <!--                OTHER TYPE-->
                  <InputNumber v-else @input="updatePosition(position, $event.value, 'qty', 99999, -99999)"
                               :locale="computedNumberInputLocale"
                               v-model="position.qty"
                               :inputClass="{'p-invalid' : submitted && !position.qty}"
                               class="table-body__qty-input"
                               mode="decimal"
                               :max="99999"
                               :min="-99999"
                               showButtons
                               :minFractionDigits="2"
                               :maxFractionDigits="2"
                               autocomplete="off"/>
                  <div v-if="position.exceedsFixedQty && position.fixedPositionMaxQty && +position.fixedPositionMaxQty" class="table-small-text warning-color">
                    <span v-if="position.fixedPositionMaxQty < 0">Min</span>
                    <span v-else>Max</span>
                    qty: {{ position.fixedPositionMaxQty }}
                  </div>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <InputNumber @input="updatePosition(position, $event.value, 'purchase_price', 1000000, 0)"
                               :locale="computedNumberInputLocale"
                               v-model="position.purchase_price"
                               :disabled="position.type === 1 && !position.isNew"
                               :inputClass="{'p-invalid' : submitted && !position.purchase_price}"
                               class="table-body__purchase-input"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="5"
                               autocomplete="off"/>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <InputNumber :locale="computedNumberInputLocale"
                               @input="updatePosition(position, $event.value, 'sell_price', 1000000, 0)"
                               disabled
                               v-model="position.sell_price"
                               class="table-body__sell-input"
                               mode="decimal"
                               :min="0"
                               :max="1000000"
                               :minFractionDigits="2"
                               :maxFractionDigits="5"
                               autocomplete="off"/>
                </td>
<!--                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">-->
<!--                  <InputNumber :locale="computedNumberInputLocale" @input="updatePosition(position, $event.value, 'discount')" v-model="position.discount" class="table-body__discount-input" mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>-->
<!--                </td>-->
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.sum" mode="decimal" class="table-body__sum-input" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <CustomDropdown @change="changeTax(position, $event.value)"
                            class="table-body__tax-input"
                            :class="{'p-invalid' : submitted && !position.selectedTax}"
                            v-model="position.selectedTax"
                            :options="taxes"
                            :filter="false"
                            optionLabel="value"
                            :showClear="false">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{ slotProps.value.value }}%</span>
                      </div>
                      <span v-else style="visibility: hidden">.</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.value }}% ({{ slotProps.option.name }})</span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <InputNumber :locale="computedNumberInputLocale" v-model="position.total" class="table-body__total-input" mode="decimal" disabled :minFractionDigits="2" :maxFractionDigits="4"/>
                </td>
                <td v-if="invoiceHavePositionsWithExceedsFixedQty" :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <i v-if="position.exceedsFixedQty"
                     class="pi pi-exclamation-circle warning-color"
                     v-tooltip.top="'Exceeds fixed qty'">
                  </i>
                </td>
                <td v-if="invoiceHaveNotNewPositions" :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
<!--                  <div v-if="!position.isNew || position.exceedsFixedQty" class="p-d-flex p-ai-center p-flex-wrap">-->
                  <PositionHistoryButton :position="position"/>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <router-link v-if="position.order" :to="{ path: `/orders/${position.order.number}` }" target="_blank">
                    <i class="ti-hummer"></i>
                  </router-link>
                </td>
                <td :class="{'new-item': position.isNew, 'warning-background': position.exceedsFixedQty}">
                  <!--                <DeleteButton v-if="position.status !== 0 && offerData.state !== 5" @click="confirmPositionDelete(position)"/>-->
                  <DeleteButton v-if="!position.isNew" @click="confirmPositionDelete(position)"/>
                  <RemoveButton v-else-if="position.isNew && position.type !== 4 && position.type !== 5 && position.type !== 7" @click="removePosition(position, index)"/>
                  <ReduceButton v-else @click="removePosition(position, index)"/>
                </td>
              </template>
            </tr>

            <tr style="background-color: inherit">
              <td style="padding-right: 10px; line-height: 1.5; text-align: right" colspan="15">
                <div class="p-text-right p-d-flex p-flex-column p-align-end">
<!--                  <div class="p-d-flex p-ai-center">-->
<!--                    <Checkbox v-model="isTaxCustom" class="p-mr-1" id="custom-tax-input-checkbox" :binary="true" />-->
<!--                    <label for="custom-tax-input-checkbox" class="pointer">Custom tax value</label>-->
<!--                  </div>-->
                  <div class="p-d-flex p-ai-center">
                    <div>Sum without tax: </div>
                    <div class="table-summary__price">{{ formatCurrency(summary.sum) }}</div>
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <div>Tax: </div>
                    <div class="table-summary__price" v-if="!isTaxCustom">{{ formatCurrency(summary.tax) }}</div>
                    <div class="table-summary__price" v-else-if="isTaxCustom">
<!--                      <CustomInputNumber v-model="summary.tax"-->
<!--                                         :locale="computedNumberInputLocale"-->
<!--                                         class="custom-tax-input"-->
<!--                                         :inputStyle="'text-align:right'"-->
<!--                                         mode="decimal"-->
<!--                                         @input="summaryTaxOnInput"-->
<!--                                         :minFractionDigits="2"-->
<!--                                         :maxFractionDigits="2"/>-->
                      <InputNumber v-model="summary.tax"
                                   :locale="computedNumberInputLocale"
                                   class="custom-tax-input"
                                   :inputStyle="'text-align:right'"
                                   mode="decimal"
                                   @input="summaryTaxOnInput"
                                   :minFractionDigits="2"
                                   :maxFractionDigits="2"/>
<!--                      <input type="number" class="custom-tax-input" :value="summary.tax">-->
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <div>Custom tax value: </div>
                    <div class="table-summary__price">
                      <Checkbox v-model="isTaxCustom" @change="customTaxCheckboxOnClick" id="custom-tax-input-checkbox" :binary="true" />
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center">
                    <div>Adjustment: </div>
                    <div class="table-summary__price">
<!--                      <CustomInputNumber v-model="summary.adjustment"-->
<!--                                         :locale="computedNumberInputLocale"-->
<!--                                         class="custom-tax-input"-->
<!--                                         @input="summaryAdjustmentOnInput"-->
<!--                                         :inputStyle="'text-align:right'"-->
<!--                                         mode="decimal"-->
<!--                                         :min="-999"-->
<!--                                         :max="999"-->
<!--                                         :minFractionDigits="2"-->
<!--                                         :maxFractionDigits="2"-->
<!--                                         autocomplete="off"/>-->

                      <InputNumber v-model="summary.adjustment"
                                   :locale="computedNumberInputLocale"
                                   class="custom-tax-input"
                                   @input="summaryAdjustmentOnInput"
                                   :inputStyle="'text-align:right'"
                                   mode="decimal"
                                   :min="-999"
                                   :max="999"
                                   :minFractionDigits="2"
                                   :maxFractionDigits="2"
                                   autocomplete="off"/>
                    </div>
                  </div>
                  <div class="p-d-flex p-ai-center p-text-bold">
                    <div>Grand total: </div>
<!--                    <div class="table-summary__price">{{ formatCurrency(computeGrandTotal) }}</div>-->
                    <div class="table-summary__price">{{ formatCurrency(summary.grand_total) }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--        <div class="p-float-label p-input-icon-right" >-->
<!--          <i class="ti-comment-alt"></i>-->
<!--          <Textarea id="invoice_comment" v-model.trim="itemData.comment" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="invoice_comment">{{ $t('Comment') }}</label>-->
<!--        </div>-->
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="2"
                              v-model="itemData.comment"
                              :maxChars="commentMaxChars"
                              :id="'purchase-invoice-comment'"
                              :label="$t('Comment')"/>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton || ((selectedIssuedDateFinancialPeriodIsClosed || invoiceFinancialPeriodIsClosed) && !userIsAdminOrAccountant)" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>

  <ConfirmDeleteModal
      :modalDataIsLoading="modalDataIsLoading"
      :visible="confirmPositionDeleteModal"
      @close="closeConfirmPositionDeleteModal"
      @delete-item="deletePosition"
      :data="positionToDelete.name">
  </ConfirmDeleteModal>

<!--  @update-items="updateItems"-->
  <SupplierModal :visible="supplierModal"
                 :modalLayer="2"
                 :item="supplier"
                 :taxes="taxesData"
                 @close="closeSupplierModal"
                 @update-item="updateSupplier">
  </SupplierModal>

  <WarehouseItemModal :visible="warehouseItemModal"
                      :item="warehouseItem"
                      @close="closeWarehouseItemModal"
                      @update-item="updateWarehouseItem">
  </WarehouseItemModal>

  <RequestPositionsModal :visible="requestPositionsModal"
                         :positionIsPushed="positionIsPushed"
                         :clearModalData="clearModalData"
                         @modal-data-on-clear="clearModalData = false"
                         @close="closeRequestPositionsModal"
                         @add-position="addPosition"
                         @remove-position="removeRequestPosition"
                         :removeRequestPositionId="removeRequestPositionId"
                         :supplierId="selectedSupplier ? +selectedSupplier.id : null"
                         :loadRequestPositions="loadRequestPositions"
                         @items-loaded="requestPositionsOnLoad"/>

  <ReducedPositionsModal :visible="ReducedPositionsModal"
                         :positionIsPushed="positionIsPushed"
                         :clearModalData="clearModalData"
                         @modal-data-on-clear="clearModalData = false"
                         @close="closeReducedPositionsModal"
                         @add-position="addPosition"
                         @remove-position="removeReducedPosition"
                         :removeReducedPositionId="removeReducedPositionId"
                         :supplierId="selectedSupplier ? +selectedSupplier.id : null"
                         :loadReducedPositions="loadReducedPositions"
                         @items-loaded="reducedPositionsOnLoad"/>
<!--  <WarehouseRequestPositionsModal :visible="warehouseRequestPositionsModal"-->
<!--                        :clearModalData="clearModalData"-->
<!--                        @close="closeWarehouseRequestPositionsModal"-->
<!--                        @add-position="addPosition"-->
<!--                        @remove-position="removeWarehouseRequestPosition"-->
<!--                        :removeWarehouseRequestId="removeWarehouseRequestId"-->
<!--                        :supplierId="selectedSupplier ? +selectedSupplier.id : null"-->
<!--                        :loadWarehouseRequestPositions="loadWarehouseRequestPositions"-->
<!--                        @items-loaded="loadWarehouseRequestPositions = false"/>-->
  <ConfirmModal :visible="confirmEditClosedFinancialPeriodDataModal"
                :text="'Financial period already closed. ' + $t('Are you sure you want to proceed?')"
                @close="closeConfirmEditClosedFinancialPeriodDataModal"
                @confirm="editClosedFinancialPeriodDataModal">
  </ConfirmModal>
</template>

<script>
import httpClient from '@/services/http.services'
import SupplierModal from '@/pages/data_directory/components/SupplierModal'
import settings from '@/settings'
import PurchaseInvoiceStates from '@/translations/states/PurchaseInvoiceStates'
import constants from '@/constants'
import PositionHistoryButton from '@/components/PositionHistoryButton'
import RequestPositionsModal from '@/pages/finance/components/RequestPositionsModal'
import ReducedPositionsModal from '@/pages/finance/components/ReducedPositionsModal'
import formatMixins from '@/mixins/formatMixins'
import generateMixins from '@/mixins/generateMixins'
import ReduceButton from '@/components/DataTable/ReduceButton'
import RemoveButton from '@/components/DataTable/RemoveButton'
// import httpMixins from "@/mixins/httpMixins";
// import CustomInputNumber from "@/components/CustomInputNumber";
// import WarehouseRequestPositionsModal from "@/pages/finance/components/WarehousRequestPositionsModal";
import WarehouseItemModal from "@/pages/warehouse/components/WarehouseItemModal";
import ReducedPositionReasons from "@/translations/ReducedPositionReasons";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import calculateMixins from "@/mixins/calculateMixins";
import MessageBar from "@/components/MessageBar";
import permissionsMixins from "@/mixins/permissionsMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import sortingMixins from "@/mixins/sortingMixins";

export default {
  mixins: [ formatMixins, generateMixins, showErrorsMixins, overlayVisibilityMixins, calculateMixins, permissionsMixins, sortingMixins ],
  components: { SupplierModal, PositionHistoryButton, RequestPositionsModal, ReducedPositionsModal, ReduceButton, RemoveButton, WarehouseItemModal, MessageBar },
  // components: { Dropdown },
  emits: ['close', 'update-items', 'update-item'],
  name: 'purchaseInvoiceModal',
  props: {
    item: Object,
    visible: Boolean,
    taxes: Array,
    suppliers: Array,
    warehouseItems: Array,
  },
  data() {
    return {
      commentMaxChars: 500,
      invoiceHavePositionsWithExceedsFixedQty: false,
      warehouseCategories: null,
      // exportInvoiceToSmartAccounts: settings.exportPurchaseInvoiceByDefault,
      exportInvoiceToSmartAccounts: false,
      exportInvoiceToSmartAccountsDisabled: false,
      smartAccountsItems: [
        // {code: '40511', description: 'Materjalid, autovaruosad'},
        // {code: '40512', description: 'Ostetud teenused'},
        // {code: '40515', description: 'Jooksvad kulumaterjalid'},

        {code: '40511', description: 'Materjalid, autovaruosad'},
        {code: '40512', description: 'Ostetud teenused'},
        {code: '40514', description: 'Jooksvad töövahendid'},
        {code: '40515', description: 'Jooksvad kulumaterjalid'},
        {code: '40611', description: 'Ruumide rent'},
        {code: '40612', description: 'Elekter'},
        {code: '40621', description: 'Valve'},
        {code: '40622', description: 'Telefon'},
        {code: '40623', description: 'Programm ja tarkvara'},
        {code: '40628', description: 'Sisustuse kulud'},
        {code: '40629', description: 'Kontorikulud'},
        {code: '40629k', description: 'Kontorikulud'},
        {code: '40630', description: 'Postiteenused'},
        {code: '40631', description: 'Kantseleikulu'},
        {code: '40632', description: 'Vara ja seadmete kindlustus'},
        {code: '40637', description: 'Väikevahendid'},
        {code: '40638', description: 'Seadmete rent'},
        {code: '40641', description: 'Raamatupidamine'},
        {code: '40651', description: 'Reklaam'},
        {code: '40661', description: 'Kommunaalkulud'},
        {code: '40662', description: 'Jooksev remont'},
        {code: '40671', description: 'Kütus, määrdeained'},
        {code: '40672', description: 'Sõidukite jooksev remont, tehnohooldus'},
        {code: '40673', description: 'Sõidukite rent'},
        {code: '40674', description: 'Sõidukite seadmed'},
        {code: '40675', description: 'Sõidukite kindlustusmaksed'},
        {code: '40676', description: 'Parkimine'},
        {code: '40679', description: 'Ostetud transporditeenused'},
        {code: '40681', description: 'Tolli teenustasud'},
        {code: '40687', description: 'Panga teenustasud'},
        {code: '40691', description: 'Muud tegevuskulud (kaup)'},
        {code: '40691Vesi', description: 'Veepudel 19L'},
        {code: '40692', description: 'Muud tegevuskulud (teenus)'},
        {code: '40912', description: 'Kahjum põhivara mahakandmisest'},
        {code: '40951', description: 'Vastuvõtukulud'},
        {code: '40952', description: 'Annetused, kingitused'},
        {code: '40992', description: 'Viivised, intressid hankjatele'},
        {code: '41131', description: 'Intressid'},
      ],
      modalDataIsLoading: false,
      suppliersDataIsLoading: false,
      positionIsPushed: true,
      disabledAddRequestPositionsButton: false,
      disabledAddReducedPositionsButton: false,
      warehouseItemModal: false,
      warehouseItem: null,
      dataIsSending: false,
      issuedDateIsValid: false,
      paymentDueDateIsValid: false,
      isTaxCustom: false,
      suppliersData: [],
      taxesData: [],
      warehouseItemsData: [],
      ReducedPositionsModal: false,
      // addRequestsButtonIsDisabled: false,
      loadRequestPositions: false,
      loadReducedPositions: false,
      // loadWarehouseRequestPositions: false,
      clearModalData: false,
      removeRequestPositionId: null,
      removeReducedPositionId: null,
      // removeWarehouseRequestId: null,
      requestPositionsModal: false,
      // warehouseRequestPositionsModal: false,
      // lastSortingNum: 1,
      invoiceNumberExists: false,
      disableSaveButton: false,
      issued: null,
      payment_due: null,
      itemData: {},
      settings,
      selectedSupplier: null,
      selectedState: null,
      positionToUpdate: null,
      submitted: false,
      supplierModal: false,
      supplier: null,
      confirmPositionDeleteModal: false,
      positionToDelete: {},
      positions: [],
      defaultTaxId: null,
      summary: {
        sum: null,
        tax: null,
        grand_total: null
      },
      PurchaseInvoiceStates,
      constants,
      ReducedPositionReasons,
      invoiceFinancialPeriodIsClosed: false,
      selectedIssuedDateFinancialPeriodIsClosed: false,
      editClosedFinancialPeriodDataConfirmed: false,
      confirmEditClosedFinancialPeriodDataModal: false,
    }
  },
  watch: {
    suppliers(value) {
      this.suppliersData = [...value]
    },
    taxes(value) {
      this.taxesData = [...value]
    },
    // warehouseCategories(value) {
    //   this.warehouseCategoriesData = [...value]
    // },
    warehouseItems(value) {
      this.warehouseItemsData = [...value]
    },
    // issued() {
    //   this.calculatePaymentDue()
    // },
    item(value) {
      // this.suppliersData = this.suppliers
      if (!value) return false
      this.itemData = { ...value }

      this.summary.sum = value.sum ? +value.sum : 0
      this.summary.tax = value.tax ? +value.tax : 0
      this.summary.adjustment = value.adjustment ? +value.adjustment : 0
      this.summary.grand_total = value.grand_total ? +value.grand_total : 0

      if (value.is_tax_custom) {
        this.isTaxCustom = true
      }

      this.itemData.originalNumberLoweredCase = value.number?.toLowerCase()

      if (value.supplier) {
        this.selectedSupplier = value.supplier
        // this.changeSupplier()
        this.loadRequestPositions = true
        this.loadReducedPositions = true
        // this.loadWarehouseRequestPositions = true
        // this.findSupplier(value.supplier_id)
      } else {
        if (this.visible) {
          this.loadRequestPositions = true
          this.loadReducedPositions = true
          // this.loadWarehouseRequestPositions = true
        }
        this.selectedSupplier = null
      }

      if (value.issued) {
        this.issued = new Date(value.issued * 1000)
        if (this.$store.state.branchData?.financial_period_closed_due > value.issued) {
          this.invoiceFinancialPeriodIsClosed = true
          // this.disableEditing()
        }
      } else {
        this.issued = new Date()
        this.invoiceFinancialPeriodIsClosed = false
      }
      this.checkIssuedDateIsValid()

      if (value.payment_due) {
        this.payment_due = new Date(value.payment_due * 1000)
      } else {
        // this.payment_due = value.issued ? new Date(value.issued * 1000) : this.calculateDayEndDate(new Date())
        // this.payment_due = value.issued ? this.calculateDayEndDate(new Date(value.issued * 1000)) : this.calculateDayEndDate(new Date())
        this.calculatePaymentDue()
      }

      this.checkPaymentDueDateIsValid()

      if (this.item.state) {
        this.selectedState = this.PurchaseInvoiceStates.find(state => state.num === value.state)
      } else {
        this.selectedState = this.PurchaseInvoiceStates[0]
      }

      if (value.positions) {
        // let positionSorting = 1
        this.positions = value.positions.map(position => {
          const selectedTax = this.findSelectedTax(position)
          const selectedWarehouseItem = position.type === 1 || position.type === 7 ? this.findSelectedWarehouseItem(position) : null

          const selectedSmartAccountsItem = this.settings.useSmartAccounts ? this.findSmartAccountsItem(position) : null
          return {
            selectedSmartAccountsItem: selectedSmartAccountsItem,
            accounting_code: selectedSmartAccountsItem ? selectedSmartAccountsItem?.code : null,

            id: position.id,
            order_id: position.order_id,
            order: position.order,
            order_position_id: position.order_position_id,
            reduced_position_id: position.reduced_position_id,
            stock_position_id: position.stock_position_id,
            // supplier_id: this.selectedSupplier?.id ?? position.supplier_id,
            type: position.type,
            // sorting: position.sorting ? position.sorting : positionSorting++,
            sorting: position.sorting ?? null,
            name: position.name,
            code: position.code ? position.code : null,
            raw_code: position.raw_code ? position.raw_code : null,
            qty: position.qty ? +position.qty : 0,
            purchase_price: position.purchase_price ? +position.purchase_price : 0,
            sell_price: position.sell_price ? +position.sell_price : 0,
            // discount: position.discount && +position.discount !== 0 ? +position.discount : null,
            // sum: (position.purchase_price - (((position.discount || 0) / 100) * position.purchase_price)) * position.qty,
            // total: ((position.purchase_price - (((position.discount || 0) / 100) * position.purchase_price)) * position.qty) * (((position.tax?.value || 0) / 100) + 1),
            sum: position.sum ? +position.sum : 0,
            total: position.total ? +position.total : 0,
            warehouse_item_id: position.warehouse_item_id,
            selectedWarehouseItem: selectedWarehouseItem,
            is_decimal: selectedWarehouseItem ? selectedWarehouseItem.is_decimal : false,
            selectedTax: selectedTax,
            tax_id: position.tax_id ?? selectedTax.id ?? null,
            // tax_value: position.tax_value ?? selectedTax.value ?? 0,
            tax_value: selectedTax?.value ? +selectedTax.value : 0,
            tax_sum: position.tax_sum ? +position.tax_sum : 0,
            isUpdated: false,
            state: position.state ? +position.state : null,
            status: position.status,
            created_at: position.created_at ? position.created_at : null,
            creator: position.creator ? position.creator : null,
          }
        })
      }

      // console.log(this.summary.grand_total)
      // this.updateSummary()
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false

        this.submitted = false
        this.selectedState = null
        this.positions = []
        this.warehouseItemsData = []
        this.invoiceNumberExists = false
        this.itemData = null
        this.clearModalData = true
        this.isTaxCustom = false
        this.issuedDateIsValid = false
        this.paymentDueDateIsValid = false
        // this.exportInvoiceToSmartAccounts = settings.exportPurchaseInvoiceByDefault
        this.exportInvoiceToSmartAccounts = false
        this.exportInvoiceToSmartAccountsDisabled = false
        this.suppliersData = [...this.suppliers]

        this.invoiceFinancialPeriodIsClosed = false
        this.selectedIssuedDateFinancialPeriodIsClosed = false
        this.editClosedFinancialPeriodDataConfirmed = false
      } else {
        // this.loadRequestPositions = true
        this.clearModalData = false

        if (settings.isDevMode) {
          this.exportInvoiceToSmartAccounts = false
          this.exportInvoiceToSmartAccountsDisabled = true
        } else if (settings.useSmartAccounts) {
          this.exportInvoiceToSmartAccounts = !!settings.exportSalesInvoiceByDefault
        }
        // if (settings.useSmartAccounts) {
        //   this.exportInvoiceToSmartAccounts = !!settings.exportSalesInvoiceByDefault
        // }

        // this.exportInvoiceToSmartAccounts = true
        // this.exportInvoiceToSmartAccountsDisabled = false
      }
    },
    '$store.state.secondLayerIsOpened'() {
      if (!this.$store.state.secondLayerIsOpened) {
        if (this.confirmPositionDeleteModal) {
          this.closeConfirmPositionDeleteModal()
        }
        if (this.supplierModal) {
          this.closeSupplierModal()
        }
        if (this.requestPositionsModal) {
          this.closeRequestPositionsModal()
        }
        if (this.ReducedPositionsModal) {
          this.closeReducedPositionsModal()
        }
        // if (this.warehouseRequestPositionsModal) {
        //   this.closeWarehouseRequestPositionsModal()
        // }
        if (this.warehouseItemModal) {
          this.closeWarehouseItemModal()
        }
      }
    },
    '$store.state.thirdLayerIsOpened'() {
      if (!this.$store.state.thirdLayerIsOpened) {
        if (this.confirmEditClosedFinancialPeriodDataModal) {
          this.closeConfirmEditClosedFinancialPeriodDataModal()
        }
      }
    }
  },
  methods: {
    closeConfirmEditClosedFinancialPeriodDataModal() {
      this.confirmEditClosedFinancialPeriodDataModal = false
      // this.$store.commit('closeRequiredAppLayer') //don't work... why ?
      this.$store.commit('toggleThirdLayer', false)

    },
    openConfirmEditClosedFinancialPeriodDataModal() {
      this.confirmEditClosedFinancialPeriodDataModal = true
      // this.$store.commit('openRequiredAppLayer') //don't work... why ?
      this.$store.commit('toggleThirdLayer', true)
    },
    editClosedFinancialPeriodDataModal() {
      this.closeConfirmEditClosedFinancialPeriodDataModal()
      this.editClosedFinancialPeriodDataConfirmed = true
      this.saveItem()
    },
    generateNewPositionsData() {
      const positions = this.positions?.filter(position => position.isNew)
      if (!positions?.length) return null

      const newPosition = this.generatePositionsData(positions)
      return newPosition
    },
    generateUpdatedPositionsData() {
      const positions = this.positions?.filter(position => position.isUpdated && position.status)
      if (!positions?.length) return null

      const updatedPosition = this.generatePositionsData(positions)
      return updatedPosition
    },
    generatePositionsData(positions) {
      const positionData = positions.map(position => {
        return {
          id: position.id,
          temp_id: position.temp_id,
          // purchase_invoice_id: position.purchase_invoice_id,
          order_position_id: position.order_position_id,
          reduced_position_id: position.reduced_position_id,
          warehouse_item_id: position.warehouse_item_id,
          type: position.type,
          accounting_code: position.accounting_code,
          code: position.code ? position.code.trim() : null,
          raw_code: position.raw_code,
          name: position.name ? position.name.trim() : null,
          qty: position.qty,
          purchase_price: position.purchase_price,
          sell_price: position.sell_price,
          tax_id: position.tax_id,
          tax_value: position.tax_value,
          tax_sum: position.tax_sum,
          sum: position.sum,
          total: position.total,
          status: position.status,
          sorting: position.sorting
        }
      })
      return positionData
    },
    requestPositionsOnLoad() {
      this.loadRequestPositions = false
      // this.disabledAddRequestPositionsButton = !!arrayIsEmpty
    },
    reducedPositionsOnLoad() {
      this.loadReducedPositions = false
      // this.disabledAddReducedPositionsButton = !!arrayIsEmpty
    },
    // addWarehouseRequestPositions() {
    //   this.warehouseRequestPositionsModal = true
    //   if (!this.$store.state.secondLayerIsOpened) {
    //     this.$store.commit('toggleSecondLayer', true)
    //   }
    // },
    // findSmartAccountsItem(position) {
    //   const selectedSmartAccountsItem = position.accounting_code ? this.smartAccountsItems.find(i => +i.code === +position.accounting_code) : null
    //   if (selectedSmartAccountsItem) {
    //     position.accounting_code = selectedSmartAccountsItem.code
    //     return selectedSmartAccountsItem
    //   } else {
    //     const defaultCode = this.findDefaultSmartAccountsItem(position)
    //     if (defaultCode) {
    //       position.accounting_code = defaultCode.code
    //       return defaultCode
    //     }
    //     // return null
    //   }
    // },
    findSmartAccountsItem(position) {
      const currentAccountingCode = position.accounting_code
      const smartAccountsItem = position.accounting_code ? this.smartAccountsItems.find(i => +i.code === +position.accounting_code) : null

      let selectedSmartAccountsItem = null
      if (smartAccountsItem) {
        position.accounting_code = smartAccountsItem.code
        selectedSmartAccountsItem = smartAccountsItem
      } else {
        const defaultSmartAccountsItem = this.findDefaultSmartAccountsItem(position)
        if (defaultSmartAccountsItem) {
          position.accounting_code = defaultSmartAccountsItem.code
          selectedSmartAccountsItem = defaultSmartAccountsItem
        }
      }
      if (!position.isNew && (+currentAccountingCode !== +position.accounting_code)) {
        position.isUpdated = true
      }

      return selectedSmartAccountsItem
    },
    findDefaultSmartAccountsItem(position) {
      let selectedSmartAccountsItem = null

      if (position.type === 3) {
        selectedSmartAccountsItem = this.smartAccountsItems.find(i => +i.code === +this.settings.smartAccountsSettings.defaultPurchaseInvoiceCodes.consumable)
      } else {
        selectedSmartAccountsItem = this.smartAccountsItems.find(i => +i.code === +this.settings.smartAccountsSettings.defaultPurchaseInvoiceCodes.product)
      }
      return selectedSmartAccountsItem
    },
    changeSmartAccountsItem(position) {
      // if (!position.selectedSmartAccountsItem) return false
      position.isUpdated = !position.isNew
      position.accounting_code = position.selectedSmartAccountsItem?.code ?? null
    },
    changeIssuedDate() {
      if (!this.issued) return false
      this.issued = this.calculateDateWithCurrentTime(this.issued)

      this.checkIssuedDateIsValid()
      this.calculatePaymentDue()
      this.checkIssuedDateFinancialPeriodIsClosed()
    },
    checkIssuedDateFinancialPeriodIsClosed() {
      if (this.issued && this.$store.state.branchData?.financial_period_closed_due > +new Date(this.issued / 1000)) {
        this.selectedIssuedDateFinancialPeriodIsClosed = true
        // this.disableEditing()
      } else {
        this.selectedIssuedDateFinancialPeriodIsClosed = false
      }
    },
    changePaymentDueDate() {
      if (!this.payment_due) return false
      this.payment_due = this.calculateDayEndDate(this.payment_due)
      this.checkPaymentDueDateIsValid()
    },
    checkIssuedDateIsValid() {
      this.issuedDateIsValid = this.checkDateIsValid(this.issued)
    },
    checkPaymentDueDateIsValid() {
      this.paymentDueDateIsValid = this.checkDateIsValid(this.payment_due)
    },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    calculatePaymentDue() {
      if (!this.issued || !this.issuedDateIsValid) return false

      if (this.selectedSupplier && this.selectedSupplier.days_to_pay) {
        this.payment_due = new Date(+this.issued + (this.selectedSupplier.days_to_pay * 24 * 60 * 60 * 1000))
      } else {
        this.payment_due = this.issued
      }
      this.checkPaymentDueDateIsValid()
      this.changePaymentDueDate()
    },
    summaryTaxOnInput($event) {
      let value = $event.value
      if (value === '-') {
        value = null
      }
      this.summary.tax = value
      this.updateSummary()
    },
    summaryAdjustmentOnInput($event) {
      let value = $event.value
      if (value === '-') {
        value = null
      }
      this.summary.adjustment = value
      this.updateSummary()
    },
    customTaxCheckboxOnClick() {
      if (!this.isTaxCustom) {
        this.updateSummary()
      }
    },
    // findSelectedWarehouseItem(position) {
    //   if (!position.warehouseItem?.id) return false
    //   const warehouseItem = this.warehouseItemsData.filter(item => +item.id === +position.warehouseItem.id)[0]
    //   if (warehouseItem) {
    //     return warehouseItem
    //   } else {
    //     this.warehouseItemsData.push(position.warehouseItem)
    //     return position.warehouseItem
    //   }
    // },
    findSelectedWarehouseItem(position) {
      if (!position.warehouseItem?.id) return false
      const warehouseItem = this.warehouseItemsData.find(item => +item.id === +position.warehouseItem.id)
      if (warehouseItem) {
        return warehouseItem
      } else {
        this.warehouseItemsData.push(position.warehouseItem)
        return position.warehouseItem
      }
    },
    findSelectedTax(position) {
      if (!position.tax?.id) return false
      const tax = this.taxesData.find(tax => +tax.id === +position.tax.id)
      if (tax) {
        return tax
      } else {
        this.taxesData.push(position.tax)
        return position.tax
      }
    },
    async addPosition(position, type) {
      if (!position) return false
      this.positionIsPushed = false

      if (!this.selectedSupplier ||
          (this.selectedSupplier && this.selectedSupplier.id !== position.supplier?.id)) {
        // this.findSupplier(position.supplier_id)
        this.selectedSupplier = position.supplier
        this.changeSupplier()
      }

      const reducedPositionData = position.position_data
      const purchasePrice = reducedPositionData ? (reducedPositionData?.purchase_price ? +reducedPositionData.purchase_price : 0) : (position.purchase_price ? +position.purchase_price : 0 )
      // const sellPrice = reducedPositionData ? (reducedPositionData?.sell_price ? +reducedPositionData.sell_price : 0) : (position.sell_price ? +position.sell_price : 0)

      const positionQty = position.qty ? +position.qty : 0
      const orderedQty = position.ordered_qty ? +position.ordered_qty : 0
      const fixedQty = position.fixed_qty ? +position.fixed_qty : 0
      const unfixedQty = type === 4 ? orderedQty - fixedQty : positionQty - fixedQty

      let newActualQty = 0
      if (type === 4) {
        newActualQty = orderedQty - fixedQty
      } else if (type === 5) {
        newActualQty = (positionQty - fixedQty) * -1
      } else if (type === 7) {
        newActualQty = positionQty - fixedQty
      }

      // let sellPrice = 0
      // if (type === 4) { // Order position type request
      //   sellPrice = position.sum / positionQty
      // } else if (type === 5) { // Return
      //   if (!position.order_position_id && !position.reason) { // Return request position made from Purchase invoice
      //     sellPrice = position.sell_price ? +position.sell_price : 0
      //   } else { // Return request position made from Order
      //     if (reducedPositionData?.qty && reducedPositionData?.sum) {
      //       sellPrice = reducedPositionData.sum / reducedPositionData.qty
      //     } else {
      //       sellPrice = position.sell_price ? +position.sell_price : 0
      //     }
      //   }
      // } else if (type === 7) { // Return warehouse request
      //   if (reducedPositionData?.qty && reducedPositionData?.sum) {
      //     sellPrice = reducedPositionData.sum / reducedPositionData.qty
      //   } else {
      //     sellPrice = position.sell_price ? +position.sell_price : 0
      //   }
      // }

      let sellPrice = 0
      if (type === 4) { //Order position type request
        // sellPrice = +positionQty ? (position.sum / positionQty) : (position.sell_price ? +position.sell_price : 0)
        sellPrice = +positionQty ? (position.sum / positionQty) : 0
      // } else if (type === 5 || type === 7) { //Return request || warehouse request
      } else if (type === 5) { //Return request
        sellPrice = position.sell_price ? +position.sell_price : 0
        if (reducedPositionData) {
          if (reducedPositionData?.qty && reducedPositionData?.sum) {
            if (!+reducedPositionData.qty) {
              sellPrice = 0
            } else {
              sellPrice = +reducedPositionData.sum / +reducedPositionData.qty
            }
          } else {
            sellPrice = reducedPositionData?.sell_price ? +reducedPositionData.sell_price : 0
          }
        }
      }

      this.pickTax(position)

      const positionSum = purchasePrice * newActualQty
      const positionTotal = purchasePrice * newActualQty * ((position.tax_value / 100) + 1)
      const positionTaxSum = (purchasePrice * newActualQty) * (position.tax_value / 100)

      const newPosition = {
        id: type === 4 || type === 5 ? 'temp_' + Date.now() + '_' + Math.floor(Math.random() * 40) : null,
        type: type,
        order_id: position.order?.id,
        order: position.order,
        sorting: this.findPositionsLastSortingValue(),
        name: reducedPositionData ? reducedPositionData.name : position.name,
        code: reducedPositionData ? reducedPositionData.code : position.code,
        raw_code: reducedPositionData ? this.generateRawCode(reducedPositionData.code) : this.generateRawCode(position.code),
        purchase_price: purchasePrice,
        sell_price: sellPrice,
        supplier_id: position.supplier?.id,
        isUpdated: false,
        state: position.state ? +position.state : null,
        // status: position.status,
        status: 1,
        // created_at: position.created_at ? position.created_at : null,
        // creator: position.creator ? position.creator : null,
        isNew: true,
        selectedTax: position.selectedTax,
        tax_id: position.tax_id,
        tax_value: position.tax_value,
        order_position_id: reducedPositionData  ? position.order_position_id : position.id,
        reduced_position_id: reducedPositionData ? position.id : null,
        qty: newActualQty,
        fixed_qty: fixedQty,
        unfixed_qty: unfixedQty,
        sum: this.formatDecimal(positionSum, false),
        total: this.formatDecimal(positionTotal, false),
        tax_sum: this.formatDecimal(positionTaxSum, false)
      }

      if (type === 7) {
        let searchString = newPosition.raw_code ?? newPosition.name ?? null
        if (searchString) {
          const result = await this.tryToFindWarehouseItem(searchString)
          // console.log(result)

          newPosition.searchData = searchString

          if (result) {
            newPosition.warehouseItemsData = result
            if (result.length === 1) {
              const selectedWarehouseItem = result[0]
              // newPosition.selectedWarehouseItem = selectedWarehouseItem
              // newPosition.warehouse_item_id = selectedWarehouseItem.id
              // this.changeWarehouseItem(newPosition, result[0])
              this.changeReducedWarehouseItem(newPosition, selectedWarehouseItem)

            }
          }
        }
      }

      if (this.settings.useSmartAccounts) {
        const smartAccountsItem = this.findDefaultSmartAccountsItem(newPosition)
        if (smartAccountsItem) {
          newPosition.selectedSmartAccountsItem = smartAccountsItem
          newPosition.accounting_code = smartAccountsItem.code
        }
      }

      this.positions.push(newPosition)
      this.updateSummary()

      this.positionIsPushed = true
    },
    changeReducedPositionReason(position) {
      position.reasonId = position.selectedReason?.id
    },
    changeReducedWarehouseItem(position, selectedWarehouseItem = null) {
      const newSelectedWarehouseItem = selectedWarehouseItem ?? position.selectedWarehouseItem ?? null
      if (!newSelectedWarehouseItem) return false
      position.selectedWarehouseItem = newSelectedWarehouseItem
      position.warehouse_item_id = newSelectedWarehouseItem.id
    },
    async tryToFindWarehouseItem(searchString) {
      if (!searchString) return null
      // searchString = '141224141244142214124'
      let result = null
      try {
        const { status, data } = await httpClient('warehouse/get-items?search=' + searchString)
        if (status === 200 && data?.length) {
          result = data
        }
      } catch(err) {
        this.showError(err)
      }
      return result
    },
    async checkNumberExists() {
      try {
        const { status, data } = await httpClient.post('purchase-invoice/check-number-exists', { number: this.itemData.number })
        if (status === 200 && data && this.itemData.originalNumberLoweredCase !== this.itemData.number.toLowerCase()) {
          this.invoiceNumberExists = true
        } else {
          this.invoiceNumberExists = false
        }
      } catch(err) {
        this.showError(err)
      }
    },
    async searchWarehouseItems(event, position) {
      let searchData = event.value
      // if (searchData) {
        searchData = searchData.replace(' ','%20')
        try {
          const { status, data } = await httpClient('warehouse/get-items?search=' + searchData)
          if (status === 200 && data?.length) {
            position.warehouseItemsData = data
          }
        } catch(err) {
          this.showError(err)
        }
      // } else {
      //   position.warehouseItemsData = this.warehouseItemsData
      // }
      // if (searchData) {
      //   try {
      //     const { status, data } = await httpClient('warehouse/get-items?search=' + searchData)
      //     if (status === 200 && data?.length) {
      //       position.warehouseItemsData = data
      //     }
      //   } catch(err) {
      //     this.showError(err)
      //   }
      // } else {
      //   position.warehouseItemsData = this.warehouseItemsData
      // }
    },
    async searchSuppliers(event) {
      let searchData = event.value
      // if (searchData) {
      this.suppliersDataIsLoading = true

      // if (searchData?.length) {
      searchData = searchData.replace(' ','%20')
      // }

      try {
        const { status, data } = await httpClient('supplier/get-suppliers?search=' + searchData)
        if (status === 200 && data) {
          this.suppliersData = data
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.suppliersDataIsLoading = false

        if (this.selectedSupplier) {
          const selectedSupplierInArray = !!this.suppliersData.find(s => s.id === this.selectedSupplier.id)
          if (!selectedSupplierInArray) {
            this.suppliersData.push(this.selectedSupplier)
          }
        }
      }
      // }
    },
    addNewSupplier() {
      // this.position = position
      this.supplierModal = true
      this.supplier = {}
      if (!this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', true)
      }
    },
    // updateItems() {
    //   this.$emit('update-items')
    // },
    updateItem() {
      const id = this.itemData?.id
      if (!id) return
      this.$emit('update-item', id)
    },
    updateSupplier(supplier) {
      this.selectedSupplier = supplier
      const supplierInList = !!this.suppliersData?.find(i => i.id === supplier.id)
      if (supplier.isNew || !supplierInList) {
        this.suppliersData.push(supplier)
      }
      this.searchData = null
      this.changeSupplier()
      // this.updateItem()
    },
    // changeSupplier(clearModalData = false) {
    changeSupplier() {
      if (!this.selectedSupplier) {
        this.$refs.selectSupplierDropdown?.clearFilterValue()
        this.suppliersData = [...this.suppliers]
      }

      this.loadRequestPositions = true
      this.loadReducedPositions = true

      // if (clearModalData) {
        // console.log('aa')
      //   this.clearModalData = true
      // }

      // this.loadWarehouseRequestPositions = true
      this.calculatePaymentDue()
    },
    addNewWarehouseItem(position, isReducedPosition = false) {
      if (isReducedPosition) {
        this.warehouseItem = {
          code: position.code,
          raw_code: position.raw_code ? position.raw_code : (position.code ? this.generateRawCode(position.code) : null),
          name: position.name,
          purchase_price: position.purchase_price,
          sell_price: position.sell_price,
        }
      } else {
        this.warehouseItem = {}
      }
      this.position = position
      this.warehouseItemModal = true

      this.$store.commit('toggleSecondLayer', true)
    },
    closeWarehouseItemModal() {
      this.warehouseItemModal = false
      this.warehouseItem = {}
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    updateWarehouseItem(warehouseItem) {
      if (!warehouseItem) return false
      this.position.selectedWarehouseItem = warehouseItem
      if (warehouseItem.isNew) {
        this.warehouseItemsData.push(warehouseItem)
      }
      this.position.warehouseItemsData = this.warehouseItemsData
      this.position.searchData = null
      this.changeWarehouseItem(this.position)
    },
    changeWarehouseItem(position) {
      position.isUpdated = !position.isNew
      const newWarehouseItem = position.selectedWarehouseItem
      if (newWarehouseItem) {
        position.is_decimal = !!newWarehouseItem.is_decimal
        // position.qty = position.is_decimal ? +(position.qty || 0).toFixed(2) : +(position.qty || 0).toFixed()
        position.qty = position.is_decimal ? this.formatDecimal(position.qty, false) : this.formatDecimal(position.qty, false, 0)
        position.code = newWarehouseItem.code
        position.raw_code = newWarehouseItem.raw_code ? newWarehouseItem.raw_code : this.generateRawCode(newWarehouseItem.code)
        position.name = newWarehouseItem.name
        position.purchase_price = newWarehouseItem.purchase_price ? +newWarehouseItem.purchase_price : 0
        position.sell_price = newWarehouseItem.sell_price ? +newWarehouseItem.sell_price : 0
        position.warehouse_item_id = newWarehouseItem.id
        // position.selectedWarehouseItem.qty -= position.qty
      } else {
        position.is_decimal = false
        position.code = null
        position.raw_code = null
        position.name = null
        position.purchase_price = null
        position.sell_price = null
        position.warehouse_item_id = null
      }

      this.calculatePositionSummary(position)
      // this.updateConsumable()
      this.updateSummary()
    },
    closeSupplierModal() {
      this.supplierModal = false
      this.supplier = {}
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    async getUndefinedSupplier(supplierId) {
      try {
        const { status, data } = await httpClient.post('supplier/get-supplier-by-id', { id: supplierId })
        if (status === 200) {
          if (data) {
            if (!this.suppliersData.find(supplier => supplier.id === supplierId)) {
              this.suppliersData.push(data)
            }
            this.selectedSupplier = data
          } else {
            this.selectedSupplier = null
          }
          this.changeSupplier()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    addNewPosition(type) {
      const position = {
        type: type,
        name: '',
        qty: 1.00,
        // sum: 0,
        // total: 0,
        status: 1,
        isNew: true,
        // sorting: this.lastSortingNum++,
        sorting: this.findPositionsLastSortingValue(),
        // discount: null
      }

      if (this.settings.useSmartAccounts) {
        const smartAccountsItem = this.findDefaultSmartAccountsItem(position)
        if (smartAccountsItem) {
          position.selectedSmartAccountsItem = smartAccountsItem
          position.accounting_code = smartAccountsItem.code
        }
      }

      // if (type === 1 && this.selectedCustomer && this.selectedCustomer.services_discount) {
      //   position.discount = +this.selectedCustomer.services_discount
      // } else if ((type === 2 || type === 3) && this.selectedCustomer && this.selectedCustomer.products_discount) {
      //   position.discount = +this.selectedCustomer.products_discount
      // } else {
      //   position.discount = 0
      // }

      this.pickTax(position)

      // if (this.selectedSupplier?.tax_id) {
      //   console.log(1)
      //   position.selectedTax = this.taxesData.filter(tax => tax.id === this.selectedSupplier.tax_id)?.[0]
      //   position.tax_id = this.selectedSupplier.tax_id
      //   console.log(position.selectedTax)
      // } else {
      //   console.log(2)
      //   position.selectedTax = this.taxesData.filter(tax => tax.is_default)[0] ? this.taxesData.filter(tax => tax.is_default)[0] : null
      //   position.tax_id = this.taxesData.filter(tax => tax.is_default)[0] ? this.taxes.filter(tax => tax.is_default)[0].id : null
      // }

      if (type === 2) {
        position.state = 1
      }

      this.positions.push(position)
      // this.$toast.add({severity:'success', detail: 'Position added', life: this.settings.toastLife})
    },
    findPositionsLastSortingValue() {
      if (!this.positions?.length) return 1
      let maxValue = 1;
      const values = Object.values(this.positions);
      values.map((el) => {
        //getting the value from each object and
        //comparing to existing value
        const valueFromObject = el.sorting;
        maxValue = Math.max(maxValue, valueFromObject);
      });
      if (maxValue < this.positions.length) {
        maxValue = this.positions.length
      }
      ++maxValue
      // console.log(maxValue)
      return maxValue
    },
    pickTax(position) {
      // if (!item.selectedTax) return false
      // if (position.selectedTax) {
      //   return false
      // }
      const selectedTax = this.selectedSupplier?.tax_id ? this.taxesData.find(tax => tax.id === this.selectedSupplier.tax_id) : null
      if (selectedTax) {
        position.selectedTax = selectedTax
        position.tax_id = selectedTax.id
        position.tax_value = selectedTax.value
      } else {
        const defaultTax = this.taxesData?.find(tax => tax.is_default) ?? null
        if (defaultTax) {
          position.selectedTax = defaultTax
          position.tax_id = defaultTax.id
          position.tax_value = defaultTax.value
        }
      }
    },
    updatePosition(position, value, param, maxValue = null, minValue = null) {
      // if (((maxValue || maxValue === 0) && value > maxValue) || ((minValue || minValue === 0) && value < minValue)) return false
      if (value && ((maxValue || maxValue === 0) && value > maxValue)) {
        value = maxValue
      }

      if (value && ((minValue || minValue === 0) && value < minValue)) {
        value = minValue
      }

      if (value && minValue === 0 && value === '-') {
        value = null
      }

      position.isUpdated = !position.isNew

      if (param === 'name') {
        return false
      }

      if (param === 'code') {
        position.raw_code = this.generateRawCode(position.code)
        return false
      }

      // if (param === 'discount') {
      //   if (value > 100) {
      //     value = 100
      //   }
      // }

      if (param === 'qty') {
        if (value === '-') {
          value = null
        }
      }

      position[param] = value
      // if (position.purchase_price) {
      // }

      this.calculatePositionSummary(position)
      this.updateSummary()
    },
    // calculatePositionSummary(position) {
    //   const qty = this.formatDecimal(position.qty)
    //   const purchasePrice = this.formatDecimal(position.purchase_price)
    //   // const sellPrice = position.sell_price ?? 0
    //   const discount = this.formatDecimal(position.discount)
    //   const taxValue = position.selectedTax ? this.formatDecimal(position.selectedTax.value) : 0
    //
    //   position.sum = (purchasePrice - ((discount / 100) * purchasePrice)) * qty
    //   position.total = ((purchasePrice - ((discount / 100) * purchasePrice)) * qty) * ((taxValue / 100) + 1)
    //
    //   position.purchase_sum = purchasePrice * qty
    //   position.discount_sum = (purchasePrice * qty) - position.sum
    //   position.tax_sum = position.total - position.sum
    // },
    calculatePositionSummary(position) {
      const qty = position.qty ?? 0
      const purchasePrice = position.purchase_price ?? 0
      const taxValue = position.selectedTax ? position.selectedTax.value : 0

      // position.purchase_sum = purchasePrice * qty
      const positionSum = purchasePrice * qty
      const positionTaxSum = positionSum * (taxValue / 100)
      // const positionTotal = positionSum + positionTaxSum
      position.sum = this.formatDecimal(positionSum, false)
      // position.tax_sum = this.formatDecimal(positionTaxSum, false)
      position.tax_sum = this.formatDecimal(positionTaxSum, false)
      position.total = this.formatDecimal(position.sum + position.tax_sum, false)
    },
    updateSummary() {
      let positionsSum = 0
      let positionsTaxSum = 0
      // let positionsGrandTotal = 0

      if (this.positions?.length) {
        const activePositions = [...this.positions].filter(position => position.status === 1)
        // positionsSum = activePositions.reduce((sum, position) => {
        //   return sum + this.formatDecimal(position.sum)
        // }, 0)
        //
        // positionsTotal = activePositions.reduce((sum, position) => {
        //   return sum + this.formatDecimal(position.total)
        // }, 0)
        //
        // positionsTaxSum = activePositions.reduce((sum, position) => {
        //   return sum + this.formatDecimal(position.tax_sum)
        // }, 0)

        activePositions?.forEach(position => {
          // positionsSum += this.formatDecimal(position.sum, false)
          // positionsGrandTotal += this.formatDecimal(position.total, false)
          // positionsTaxSum += this.formatDecimal(position.tax_sum, false)
          const sum = position.sum || 0
          const taxSum = position.tax_sum || 0

          positionsSum += sum
          positionsTaxSum += taxSum
          // positionsGrandTotal += position.total || 0
          // positionsGrandTotal += sum + taxSum
        })
      }

      // console.log(positionsSum, positionsTaxSum, positionsGrandTotal)

      this.summary.sum = this.formatDecimal(positionsSum, false)
      // this.summary.grand_total = this.formatDecimal(positionsGrandTotal, false)
      // this.summary.grand_total = positionsSum + positionsTaxSum

      if (!this.isTaxCustom) {
        this.summary.tax = this.formatDecimal(positionsTaxSum, false)
      }

      this.summary.grand_total = this.formatDecimal(this.summary.sum + this.summary.tax, false)

      if (this.summary.adjustment && +this.summary.adjustment) {
        this.summary.grand_total += +this.summary.adjustment
      }

      // console.log(this.summary)
    },
    changeTax(position, newTax) {
      position.isUpdated = !position.isNew

      if (position.purchase_price) {
        position.selectedTax = newTax
        position.tax_id = newTax.id
        position.tax_value = newTax.value
        this.calculatePositionSummary(position)
      }
      this.updateSummary()
    },
    removePosition(position, index) {
      // console.log(position.reduced_position_id, position.order_position_id)
      if (position.type === 4) {
        this.removeRequestPositionId = position.order_position_id
      } else if (position.type === 5 || position.type === 7) {
        this.removeReducedPositionId = position.reduced_position_id
      }
      // else if (position.type === 7) {
      //   this.removeWarehouseRequestId = position.reduced_position_id
      // }
      setTimeout(() => {
        this.removeRequestPositionId = null
        this.removeReducedPositionId = null
        // this.removeWarehouseRequestId = null
      }, 10)

      this.positions.splice(index, 1)
      this.updateSummary()
    },
    removeRequestPosition(positionId) {
      this.positions = this.positions.filter(p => p.order_position_id !== positionId)
      this.updateSummary()
    },
    removeReducedPosition(positionId) {
      this.positions = this.positions.filter(p => p.reduced_position_id !== positionId)
      this.updateSummary()
    },
    removeWarehouseRequestPosition(positionId) {
      this.positions = this.positions.filter(p => p.reduced_position_id !== positionId)
      this.updateSummary()
    },
    confirmPositionDelete(positionToDelete) {
      this.positionToDelete = positionToDelete
      this.confirmPositionDeleteModal = true
      if (!this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', true)
      }
    },
    addRequestPositions() {
      this.requestPositionsModal = true
      if (!this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', true)
      }
    },
    addReducedPositions() {
      this.ReducedPositionsModal = true
      if (!this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', true)
      }
    },
    closeRequestPositionsModal() {
      this.requestPositionsModal = false
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    closeReducedPositionsModal() {
      this.ReducedPositionsModal = false
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    // closeWarehouseRequestPositionsModal() {
    //   this.warehouseRequestPositionsModal = false
    //   if (this.$store.state.secondLayerIsOpened) {
    //     this.$store.commit('toggleSecondLayer', false)
    //   }
    // },
    closeConfirmPositionDeleteModal() {
      this.positionToDelete = {}
      this.confirmPositionDeleteModal = false
      if (this.$store.state.secondLayerIsOpened) {
        this.$store.commit('toggleSecondLayer', false)
      }
    },
    async deletePosition() {
      this.modalDataIsLoading = true
      this.positionToDelete.status = 0
      this.updateSummary()
      const obj = {
        positionId: this.positionToDelete.id,
        qty: this.positionToDelete.qty,
        reducedPositionDataId: this.positionToDelete.order_position_id,
        reducedPositionId: this.positionToDelete.reduced_position_id,
        warehouseItemId: this.positionToDelete.warehouse_item_id,
        invoiceId: this.itemData.id,
        // sum: this.formatDecimal(this.summary.sum, false),
        // tax: this.formatDecimal(this.summary.tax, false),
        // adjustment: this.formatDecimal(this.summary.adjustment, false),
        // // grand_total: this.formatDecimal(this.computeGrandTotal, false),
        // grand_total: this.formatDecimal(this.summary.grand_total, false),
        // adjustment: this.formatDecimal(this.summary.adjustment, false),
        // grand_total: this.formatDecimal(this.computeGrandTotal, false),
        // sum: this.positionToDelete.sum,
        // tax_sum: this.positionToDelete.tax_sum,
        // total: this.positionToDelete.total,
      }

      try {
        const { status, data } = await httpClient.post('purchase-invoice/delete-position', obj)
        if (status === 200 && data?.success) {
          // this.positionToDelete.status = 0
          this.positionToDelete.isNew = false
          this.positionToDelete.isUpdated = false

          this.positions = this.positions?.filter(p => p.id !== this.positionToDelete.id)

          if (this.positionToDelete.type === 4) {
            this.loadRequestPositions = true
          } else if (this.positionToDelete.type === 5) {
            this.loadReducedPositions = true
          }
          // else if (this.positionToDelete.type === 1) {
          //   this.loadWarehouseRequestPositions = true
          // }

          this.closeConfirmPositionDeleteModal()
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife})
          // this.$emit('update-items', this.itemData.id)
          this.$emit('update-items', this.itemData.id)
        } else if (data?.error) {
          this.positionToDelete.status = 1
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        } else if (data?.already_deleted) {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Position already deleted', life: this.settings.toastLife});
        }
      } catch(err) {
        this.positionToDelete.status = 1
        this.showError(err)
      } finally {
        this.modalDataIsLoading = false
        this.updateSummary()
      }
    },
    async saveItem() {
      this.submitted = true
      let wrongInputs = false

      this.resetExceedsFixedQtyPositions()

      if (!this.issuedDateIsValid ||
          !this.paymentDueDateIsValid ||
          this.invoiceNumberExists ||
          !this.selectedSupplier ||
          !this.itemData.number ||
          !this.issued ||
          !this.payment_due ||
          this.payment_due < this.issued ||
          !this.selectedState ||
          (this.itemData.comment && this.itemData.comment.length > this.commentMaxChars)) {
        wrongInputs = true
      }

      this.positions?.filter(position => position.status === 1)?.forEach(position => {
        if (!position.name ||
            !position.purchase_price ||
            !position.qty  ||
            !position.selectedTax ||
            (position.type === 1 || position.type === 7) && !position.selectedWarehouseItem ||
            // position.type === 6 && !position.selectedReason ||
            (this.settings.useSmartAccounts && this.exportInvoiceToSmartAccounts && !position.selectedSmartAccountsItem)) {
          wrongInputs = true
        }
      })

      if (wrongInputs) {
        return false
      }

      if ((this.selectedIssuedDateFinancialPeriodIsClosed || this.invoiceFinancialPeriodIsClosed) && !this.editClosedFinancialPeriodDataConfirmed) {
        if (this.userIsAdminOrAccountant) {
          this.openConfirmEditClosedFinancialPeriodDataModal()
        }
        return false
      }

      this.dataIsSending = true
      this.disableSaveButton = true

      const purchaseInvoice = {
        id: this.itemData.id ? this.itemData.id : null,
        number: this.itemData.number ? this.itemData.number.trim() : null,
        branch_id: this.$store.state.branchData ? this.$store.state.branchData.id : null,
        state: this.selectedState ? this.selectedState.num : null,
        supplier_id: this.selectedSupplier ? +this.selectedSupplier.id : null,
        sum: this.formatDecimal(this.summary.sum, false),
        tax: this.formatDecimal(this.summary.tax, false),
        is_tax_custom: this.isTaxCustom ? 1 : 0,
        adjustment: this.formatDecimal(this.summary.adjustment, false),
        // grand_total: this.formatDecimal(this.computeGrandTotal, false),
        grand_total: this.formatDecimal(this.summary.grand_total, false),
        comment: this.itemData.comment ? this.itemData.comment.trim() : null,
        exportInvoiceToSmartAccounts: this.exportInvoiceToSmartAccounts,
        newPositions: this.generateNewPositionsData(),
        updatedPositions: this.generateUpdatedPositionsData(),
        issued: this.issued ? +new Date(this.issued / 1000) : +new Date(Date.now() / 1000),
        payment_due: this.payment_due ? +new Date(this.payment_due / 1000) : +new Date(Date.now() / 1000),
        editClosedFinancialPeriodDataConfirmed: this.editClosedFinancialPeriodDataConfirmed,
      }

      if (this.itemData.id) {
        try {
          const { status, data } = await httpClient.post(`purchase-invoice/update`, purchaseInvoice)
          if (status === 200 && data?.success) {
            // console.log(data)
            this.$emit('update-items', this.itemData.id)
            // this.$emit('update-item', this.itemData.id)
            // this.updateItem()
            this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
            if (this.exportInvoiceToSmartAccounts && data?.export_error_message) {
              this.$toast.add({severity:'error', summary: 'Invoice export error', detail: data.export_error_message, life: this.settings.toastLife});
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else if (data?.financial_period_is_closed) {
            this.$store.commit('updateBranchDataVariable', 'financial_period_closed_due', data.financial_period)
            this.invoiceFinancialPeriodIsClosed = true
            if (this.userIsAdminOrAccountant) {
              this.openConfirmEditClosedFinancialPeriodDataModal()
            }
            // else {
            //   this.disableEditing()
            // }
          } else if (data?.fixed_positions) {
            this.invoiceHavePositionsWithExceedsFixedQty = true
            this.markExceedsFixedQtyPositions(data.fixed_positions)
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Positions already fixed', life: this.settings.toastLife});
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.editClosedFinancialPeriodDataConfirmed = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`purchase-invoice/create`, purchaseInvoice)
          // console.log(data)
          if (status === 201 && data?.success) {
            this.itemData.id = data.id
            this.$emit('update-items', this.itemData.id)
            // this.$emit('update-item', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()
            if (this.exportInvoiceToSmartAccounts && data?.export_error_message) {
              this.$toast.add({severity:'error', summary: 'Invoice export error', detail: data.export_error_message, life: this.settings.toastLife});
            }
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          } else if (data?.financial_period_is_closed) {
            this.$store.commit('updateBranchDataVariable', 'financial_period_closed_due', data.financial_period)
            this.invoiceFinancialPeriodIsClosed = true
            if (this.userIsAdminOrAccountant) {
              this.openConfirmEditClosedFinancialPeriodDataModal()
            }
            // else {
            //   this.disableEditing()
            // }
          } else if (data?.fixed_positions) {
            this.invoiceHavePositionsWithExceedsFixedQty = true
            this.markExceedsFixedQtyPositions(data.fixed_positions)
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Positions already fixed', life: this.settings.toastLife});
          } else {
            this.showUnknownError()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.disableSaveButton = false
          this.dataIsSending = false
          this.editClosedFinancialPeriodDataConfirmed = false
        }
      }
    },
    markExceedsFixedQtyPositions(fixedPositions) {
      fixedPositions?.forEach(fixedPosition => {
        const id = fixedPosition.split('/')[0]
        const maxQty = fixedPosition.split('/')[1]
        let foundInvoicePosition = this.positions.find(p => p.id === id)
        if (!foundInvoicePosition) return false
        foundInvoicePosition.exceedsFixedQty = true
        foundInvoicePosition.fixedPositionMaxQty = maxQty
      })
    },
    resetExceedsFixedQtyPositions() {
      this.invoiceHavePositionsWithExceedsFixedQty = false
      this.positions?.filter(pos => pos.exceedsFixedQty)?.forEach(p => {
        p.exceedsFixedQty = false
        p.fixedPositionMaxQty = p.ordered_qty - p.fixed_qty
      })
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    // computeGrandTotal() {
    //   const sum = this.formatDecimal(this.summary.sum, false)
    //   const tax = this.formatDecimal(this.summary.tax, false)
    //   const adjustment = this.formatDecimal(this.summary.adjustment, false)
    //   const res = sum + tax + adjustment
    //   return res
    //
    //   // return this.summary.sum + this.summary.tax + this.summary.adjustment
    // },
    supplierChangeDisabled() {
      return !!this.positions.find(position => position.status === 1 && (position.type === 4 || position.type === 5) || (position.type === 6 && !position.isNew))
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '85%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '90%', maxWidth: '1600px'}
      }
    },
    noPositions() {
      return !this.positions.find(position => position.status === 1)
    },
    invoiceHaveNotNewPositions() {
      return !!this.positions.find(position => position.status === 1 && !position.isNew)
    }
  },

}
</script>

<style scoped lang="scss">
.dropdown-wrapper {
  display: flex;
}
.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}
.dropdown__buttons {
  display: flex;
}
.dropdown__create-button{
  width: 2.357rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.table-wrapper {
  overflow-y: auto;
}

.positions-table {
  width: 100%;
  border-collapse: collapse;

  .table-body__name-input--accounting-code {
    //min-width: 90px;
    //width: 90px;
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--first {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--code {
    min-width: 100px;
    width: 10vw;
  }
  .table-body__name-input--name {
    min-width: 130px;
    width: 15vw;
  }
  .table-body__qty-input {
    min-width: 75px;
  }
  .table-body__purchase-input {
    min-width: 50px;
  }
  .table-body__sell-input {
    min-width: 50px;
  }
  .table-body__discount-input {
    min-width: 50px;
  }
  .table-body__sum-input {
    min-width: 65px;
  }
  .table-body__tax-input {
    min-width: 60px;
    width: 5vw;
  }
  .table-body__total-input {
    min-width: 65px;
  }

  .table-summary__price {
    width: 90px;
  }
}

.position-icon {
  padding: 4px 3px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  i {
    font-size: 1.1em;
  }
}

.custom-tax-input {
  font: inherit;
  color: inherit;
  margin: 4px auto;
  height: 26px;
  width: 90%;
  text-align: right!important;
  border: none;
  outline: none;
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

.dropdown-wrapper {
  display: flex;
}

.dropdown__select {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown__select--border-radius {
  width: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dropdown__select--one-button-width {
  width: calc(100% - 2.357rem);
}

//.custom-tax-input::-webkit-outer-spin-button,
//.custom-tax-input::-webkit-inner-spin-button {
//  -webkit-appearance: none;
//}
//
//.custom-tax-input,
//.custom-tax-input:hover,
//.custom-tax-input:focus {
//  appearance: none;
//  -moz-appearance: textfield;
//}

</style>