<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Requests') }}</div>
    </template>
    <Toolbar>
      <template #start v-if="!itemsArrayIsEmpty">
        <MultipleDeleteButton :label="`${$t('Delete')} ${selectedItems?.length ? `(${selectedItems?.length} items)`  : ''}`"
                              @click="confirmItemsMultipleDelete"
                              :disabled="dataTableIsLoading || !selectedItems?.length" />
      </template>
      <template #end>
<!--        <div class="p-input-icon-left">-->
<!--          <i class="pi pi-search" />-->
<!--          <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--        </div>-->
          <SearchInput :changeUrl="false" @search-items="getItems" :disabled="dataTableIsLoading" v-model="searchData" @show-spinner="isLoading = true"/>
      </template>
    </Toolbar>

    <Spinner v-if="isLoading"></Spinner>
<!--    <div class="p-formgrid p-grid p-mt-4" v-else-if="!isLoading && !$store.state.tabletLayout">-->
    <div class="p-formgrid p-grid p-mt-4" v-else>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
        <DesktopDataTable :dataTableIsLoading="dataTableIsLoading"
                          :tableName="$t('Requests')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
                  // {name: 'code', title: 'Code', width: '12%', sortable: true},
                  // {name: 'name', title: 'Name', width: '18%', sortable: true},
                  // {name: 'supplier', title: $t('Supplier'), width: '12%', sortable: false},
                  // {name: 'qty', title: 'Qty', width: '80px', sortable: true},
                  // {name: 'purchase_price', title: 'Purchase price', sortable: true},
                  // {name: 'sell_price', title: 'Sell price', sortable: true},
                  // {name: 'order', title: '', width: '40px', sortable: false},
                  // {name: 'car', title: $t('Car'), width: '12%', sortable: false},
                  // {name: 'created_at', title: $t('Created'), width: '14%', sortable: true},

                  {name: 'code', title: $t('Code'), sortable: true},
                  {name: 'name', title: $t('Name'), sortable: true},
                  {name: 'supplier', title: $t('Supplier'), sortable: false},
                  {name: 'qty', title: $t('Qty'), sortable: true},
                  {name: 'purchase_price', title: 'Buy', sortable: true},
                  {name: 'sell_price', title: 'Sell price', sortable: true},
                  {name: 'order', sortable: false},
                  {name: 'car', title: $t('Car'), sortable: false},
                  {name: 'created_at', title: $t('Created'), sortable: true},
              ]">
          <template v-slot:multiselectHeader>
            <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">
              <Checkbox :disabled="allCurrentItemsAreAdded" @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>
            </th>
          </template>
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'p-highlight': isItemSelected(item.id)}">
              <td :class="{ 'new-item': isItemAdded(item.id) }" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                <Checkbox v-if="!isItemAdded(item.id)" :class="{ 'no-pointer':isItemAdded(item.id)}" :value="item.id" v-model="selectedItems"/>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span>{{ item.code }}</span>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span>{{ item.name }}</span>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span v-if="item.supplier">{{ item.supplier.company_name }}</span>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <div style="position:relative; left:10px">{{ +item.ordered_qty - +item.fixed_qty }}
    <!--              <span>Equal: {{ !!item.qty_is_equal }}</span>--></div>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span v-if="item.purchase_price">{{ formatCurrency(item.purchase_price) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
<!--                <span v-if="item.sell_price">{{ formatCurrency(item.sell_price) }}</span>-->
                <span>{{ formatCurrency(+item.qty ? (item.sum / item.qty) : 0 ) }}</span>
              </td>
    <!--          <td :class="{ 'new-item': isItemAdded(item.id) }">-->
    <!--            <span v-if="item.order && item.order.number">-->
    <!--              <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank" :class="{'highlight&#45;&#45;link':isItemSelected(item.id)}">{{ item.order.number }}</router-link>-->
    <!--            </span>-->
    <!--            <i v-else class="ti-minus"></i>-->
    <!--          </td>-->
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span v-if="item.order && item.order.number">
                  <router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}"><i class="ti-hummer"></i></router-link>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <span v-if="item.car">
                  <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" :class="{'highlight--link':isItemSelected(item.id)}">
                    <span v-if="item.car.make || item.car.model">{{ item.car.make.name }} {{ item.car.model.name }}</span>
                    <span v-if="item.car.plate_number">, {{ item.car.plate_number }}</span>
                  </router-link>
                </span>
                <i v-else class="ti-minus"></i>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }" class="table-date-line">
                    <span v-if="item.created_at">
                      {{ formatDate(item.created_at) }},
                    </span>
                <span v-if="item.creator" class="table-date-line">
                      {{ item.creator.first_name }} {{ item.creator.last_name }}
                    </span>
              </td>
              <td :class="{ 'new-item': isItemAdded(item.id) }">
                <div class="table__actions-column-data">
                  <AddButton v-if="!isItemAdded(item.id)" @click="addPosition(item)" :disabled="dataTableIsLoading || !positionIsPushed || isItemSelected(item.id) || disableAddPositionButton"/>
                  <ReduceButton v-else @click="removePosition(item)" :disabled="dataTableIsLoading || isItemSelected(item.id)"/>
    <!--              <DeleteButton/>-->
    <!--              <DeleteButton @click="confirmItemDelete(item)"-->
    <!--                            v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11"
                      @page="changePage"
                      @change-per-page="changePerPage"
                      :changePaginationRoute="false">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Close')" icon="pi pi-times" class="p-button-text" @click="close"/>
    </template>
  </Modal>

<!--  <ConfirmDeleteModal-->
<!--      :visible="confirmPositionDeleteModal"-->
<!--      @close="closeConfirmPositionDeleteModal"-->
<!--      @delete-item="deletePosition">-->
<!--  </ConfirmDeleteModal>-->

    <ConfirmMultipleDeleteModal
        :showSpinner="dataIsSending"
        :visible="confirmItemsMultipleDeleteModal"
        @close="closeConfirmItemsMultipleDeleteModal"
        @delete-items="deleteMultipleItems">
    </ConfirmMultipleDeleteModal>

</template>

<script>
// import httpClient from '@/services/http.services'
import settings from '@/settings'
import constants from '@/constants'
import httpClient from "@/services/http.services";
import formatMixins from '@/mixins/formatMixins'
import AddButton from '@/components/DataTable/AddButton'
import ReduceButton from '@/components/DataTable/ReduceButton'
import ConfirmMultipleDeleteModal from '@/components/ConfirmMultipleDeleteModal'
import datatableMixins from '@/mixins/datatableMixins'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  components: { AddButton, ReduceButton, ConfirmMultipleDeleteModal },
  emits: ['close', 'update-items', 'add-position', 'remove-position', 'items-loaded', 'modal-data-on-clear'],
  name: 'requestPositionsModal',
  props: {
    visible: Boolean,
    isModal: {
      type: Boolean,
      default: false
    },
    removeRequestPositionId: [Number, null],
    supplierId: [Number, null],
    clearModalData: {
      type: Boolean,
      default: false
    },
    loadRequestPositions: {
      type: Boolean,
      default: false
    },
    positionIsPushed: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dataIsSending: false,
      disableAddPositionButton: false,
      confirmItemsMultipleDeleteModal: false,
      isLoading: true,
      settings,
      submitted: false,
      fieldsExpand: 'order,car',
      apiLink: 'order-position/get-request-positions',
      searchData: '',
      sort: {
        column: 'id',
        direction: '',
      },
      items: [],
      pagination: {
        totalCount: null,
        pageCount: null,
        currentPage: 1,
        perPage: 25
      },
      addedItems: [],
      selectedItems: [],
      selectAllCheckbox: [],
      constants
    }
  },
  watch: {
    // supplierId(value) {
    //   if (value && this.disableAddPositionButton) {
    //     this.disableAddPositionButton = false
    //   }
    // },
    loadRequestPositions(value) {
      if (value) {
        this.getItems()
      }
    },
    clearModalData(boolean) {
      if (boolean) {
        this.selectedItems = []
        this.selectAllCheckbox = []
        this.submitted = false

        this.addedItems = []
        this.searchData = ''
        this.pagination = {
          totalCount: null,
          pageCount: null,
          currentPage: 1,
          perPage: 25
        }
        this.sort = {
          column: 'id',
          direction: '',
        }
        this.$emit('modal-data-on-clear')
      }
    },
    removeRequestPositionId(positionId) {
      if (!positionId) return false
      const positionIndex = this.addedItems.indexOf(positionId)
      if (positionIndex !== -1) {
        this.addedItems.splice(positionIndex, 1)
      }
      // this.addedItems.splice(this.addedItems.indexOf(positionId), 1)
      // console.log(this.addedItems)
      // this.addedItems = this.addedItems.filter(item => item.id !== positionId)
      // console.log(this.addedItems.filter(item => item.id === positionId))
    },
    // supplierId() {
    //   this.getItems()
    // },
    // visible() {
      // if (!this.visible) {
      //   this.selectedItems = []
      //   this.selectAllCheckbox = []
      //   this.submitted = false
        // this.pagination = {
        //   totalCount: null,
        //   pageCount: null,
        //   currentPage: 1,
        //   perPage: 25
        // },
        // if (this.searchData.length) {
        //   this.searchData = ''
        //   this.getItems()
        // }
        // this.sort = {
        //   column: 'id',
        //   direction: '',
        // }
      // }
    // },
    selectedItems() {
      this.checkAllItemsAreSelected()
      // this.createSelectedInvoicesData()
    },
    '$store.state.thirdLayerIsOpened'() {
      if (!this.$store.state.thirdLayerIsOpened) {
        if (this.confirmItemsMultipleDeleteModal) {
          this.closeConfirmItemsMultipleDeleteModal()
        }
      }
    },
  },
  methods: {
    addPosition(position) {
      // if (!this.supplierId) {
      //   this.disableAddPositionButton = true
      // }
      if (!this.positionIsPushed) {
        return false
      }
      if (!this.supplierId) {
        this.selectedItems = []
        this.selectAllCheckbox = []
      }

      this.addedItems.push(position.id)
      this.$emit('add-position', position, 4)
      this.checkAllItemsAreSelected()
    },
    removePosition(position) {
      const positionIndex = this.addedItems.indexOf(position.id)
      if (positionIndex !== -1) {
        this.addedItems.splice(positionIndex, 1)
        this.$emit('remove-position', position.id)
      }
      this.checkAllItemsAreSelected()
    },
    changePage(data) {
      if (this.pagination.currentPage === data.page + 1) return false
      this.pagination.currentPage = data.page + 1
      this.getItems()
    },
    changePerPage(perPage) {
      if (this.pagination.perPage === perPage) return false
      this.pagination.perPage = perPage
      this.getItems()
    },
    // searchItems(searchData) {
    //   this.searchData = searchData
    //   this.getItems()
    // },
    async getItems() {
      this.dataTableIsLoading = true
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            supplierId: this.supplierId,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.searchData,
            expand: this.fieldsExpand,
            show_only_unfixed: true,
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          const arrayIsEmpty = !data?.length

          if (arrayIsEmpty) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
            // this.$emit('positions-loaded')
          } else {
            // this.$emit('disable-request-button', false)
          }

          this.$emit('items-loaded', arrayIsEmpty)
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.checkAllItemsAreSelected()
      }
    },
    async deleteMultipleItems() {
      this.dataIsSending = true
      try {
        const { status } = await httpClient.post('order-position/hide-requests', { ids: this.selectedItems })
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: this.settings.toastLife});
          this.searchData = ''

          this.getItems()
          this.closeConfirmItemsMultipleDeleteModal()
          this.unselectAllItems()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
      }
    },
    confirmItemsMultipleDelete() {
      this.confirmItemsMultipleDeleteModal = true
      this.$store.commit('toggleThirdLayer', true)
    },
    closeConfirmItemsMultipleDeleteModal() {
      this.itemToDelete = {}
      this.confirmItemsMultipleDeleteModal = false
      this.$store.commit('toggleThirdLayer', false)
    },
    checkAllItemsAreSelected() {
      let allItemsAreSelected = true
      this.items?.forEach(item => {
        if (!this.isItemSelected(item.id) && !this.isItemAdded(item.id)) {
          allItemsAreSelected = false
        }
      })

      if (allItemsAreSelected && this.selectedItems.length && !this.allCurrentItemsAreAdded) {
        this.selectAllCheckbox = [0]
      } else {
        this.selectAllCheckbox = []
      }
    },
    selectAllItems() {
      this.items.filter(item => !this.isItemSelected(item.id) && !this.isItemAdded(item.id)).forEach(item => this.selectedItems.push(item.id))
    },
    sortItems(columnName) {
      if (this.sort.column !== columnName) {
        this.sort.direction = ''
      } else {
        this.sort.direction = this.sort.direction === '' ? '-' : '';
      }
      this.sort.column = columnName
      this.getItems()
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    // computePositionWithSelect() {
    //   let
    //   this.items?.filter(item => item)
    // },
    actionButtonsCount() {
      // if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
      //   return 2
      // } else {
      //   return 1
      // }
      return 1
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '90%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '82%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '87%', maxWidth: '1400px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>